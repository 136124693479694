'use client'

import { Button } from '@/app/_primitives/Button'
import { useI18n } from '@/locales/client'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export default function EmailBox({
  handleEmail,
  variant = 'default',
  className,
}: {
  handleEmail: (email: string) => unknown
  variant?: 'lg' | 'default' | 'sm'
  className?: string
}) {
  const t = useI18n()
  const [email, setEmail] = useState(``)
  const inputCN = twMerge(
    `c-b6 w-full rounded-100 bg-white pl-40 outline-none xl:c-b3 max-lg:max-w-435`,
    variant === 'lg' ? 'h-60' : variant === 'sm' ? 'h-42' : 'h-48',
  )
  return (
    <form
      className={twMerge(`relative gap-6`, className)}
      onSubmit={(e) => {
        e.preventDefault()
        handleEmail(email)
      }}>
      <input
        className={inputCN}
        type="email"
        name="email"
        placeholder="boothbuddies@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        type="submit"
        size={variant === 'lg' ? 'default' : variant === 'sm' ? 'xs' : 'sm'}
        color="frenchBlue"
        className="flex w-full items-center justify-center whitespace-nowrap sm:w-156">
        {t('common.applyBtnLabel')}
      </Button>
      {/* <button
        type="submit"
        className={`right-0 top-0 flex items-center justify-center rounded-100 bg-[#3366B6] p-10 max-lg:w-136 lg:min-w-156`}>
        <div className="flex items-center gap-7 text-white">
          <span className={`c-b5`}>신청하기</span>
        </div>
      </button> */}
    </form>
  )
}
